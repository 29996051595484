<template>
  <div>
    <v-simple-table class="table-padding-2-no-top">
      <template v-slot:default>
        <thead class="v-data-table-header">
        <tr>
          <th role="columnheader" class="text-center"></th>
          <th role="columnheader" class="text-center" style="min-width: 100px">
            <SelectDeliveryCompany :label="$t('labels.delivery_company')" :placeholder="$t('labels.delivery_company')" name="id_delivery_company" sort-name="delivery_company_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" class="text-center" style="min-width: 100px">
            <DateRangeFilter :label="$t('labels.create_time_1')" :placeholder="$t('labels.create_time_1')" name="create_at" sort-name="create_at" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
          </th>
          <!--
          <th role="columnheader" class="text-center" style="min-width: 100px">
            <SelectWarehouse :label="$t('labels.warehouse_1')" :placeholder="$t('labels.warehouse_1')" name="id_warehouse" sort-name="warehouse_code" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          -->
          <th role="columnheader" class="text-center" style="min-width: 100px">
            <SelectEmployee :label="$t('labels.employee')" :placeholder="$t('labels.employee')" name="id_employee" sort-name="employee_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
          </th>
          <th role="columnheader" class="text-center" style="min-width: 100px">
            <SelectFilter :options="imageOptions" label="Ảnh biên bản" placeholder="Ảnh biên bản" name="has_image" sort-name="has_image" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" class="text-center" style="min-width: 100px">
            <InputFilterFromTo :label="$t('labels.order')" :placeholder="$t('labels.order')" name="count_order" sort-name="count_order" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" class="text-center" style="min-width: 100px">
            <InputFilter :label="$t('labels.tracking')" :placeholder="$t('labels.tracking')" name="order_tracking_id" @onFilter="onFilterChange" />
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id" class="text-center">
          <td><ReturnReceiveUploadFile v-if="checkRoleUpDownload(item)" :item="item" @refreshData="getList" /></td>
          <td>{{ item.delivery_company_name }}</td>
          <td>{{ formatDateTime(item.created_at) }}</td>
          <!--<td>{{ item.warehouse_code }}</td>-->
          <td>{{ item.employee_name }}</td>
          <td><ReturnReceiveImage :item="item"/></td>
          <td>{{ item.count_order }}</td>
          <td><v-btn v-if="checkRoleUpDownload(item)" small color="success" outlined @click="downloadHandoverFile(item)"><img src="@/assets/common/download.png" style="height: 24px" alt=""></v-btn></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" class="text-right">
        <v-pagination
            v-model="page"
            :length="totalPage"
            :total-visible="6"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {httpClient} from "@/libs/http";
import {IMAGE_OPTIONS} from "@/libs/const";
import { debounce } from 'lodash/function';

export default {
  components: {
    // SelectWarehouse: () => import('@/components/table/SelectWarehouse'),
    SelectDeliveryCompany: () => import('@/components/table/SelectDeliveryCompany'),
    SelectEmployee: () => import('@/components/table/SelectEmployee'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
    InputFilter: () => import('@/components/table/InputFilter'),
    InputFilterFromTo: () => import('@/components/table/InputFilterFromTo'),
    SelectFilter: () => import('@/components/table/SelectFilter'),
    ReturnReceiveUploadFile: () => import('@/components/order_online/ReturnReceiveUploadFile'),
    ReturnReceiveImage: () => import('@/components/order_online/ReturnReceiveImage'),
  },
  name: "HandoverHistory",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    sort: {},
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS]
  }),
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    checkRoleUpDownload(item) {
      if (!item.file && item.id_orders) {
        return false
      }
      if (item.count_order < 1) {
        return false
      }
      if (!window.me || !window.me.identity_id) {
        return false
      }
      if (this.checkPermission(['customer_account'])) {
        return true
      }
      const identityId = window.me.identity_id
      return identityId === item.id_identity
    },
    getList: debounce(function() {
      httpClient.post('/order-online-return-receive-list', {...this.filters, page: this.page}).then(({data}) => {
        this.totalPage = data.totalPage
        this.items = [...data.rows]
      })
    }, 1000),
    onFilterChange(filter) {
      this.filters = {...this.filters, [filter.name]: filter.value}
    },
    onSortChange(sort) {
      this.filters = {...this.filters, ...sort}
    },
    downloadHandoverFile(item) {
      const filename = `nhan-hoan-${item.tracking_id}.xlsx`
      this.downloadExcelFile(`/order-online-return-receive-download-file`, {id: item.id}, filename)
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>
